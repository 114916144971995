.Toastify__toast--dark,
.Toastify__toast--error,
.Toastify__toast--success,
.Toastify__toast--info,
.Toastify__toast--warning,
.Toastify__toast--default {
  background: #ffffff;
  color: #6a6a6a;
}

.Toastify__progress-bar {
  background-color: #f04d00;
}

.Toastify__progress-bar--default {
  background-color: #fff;
}

.Toastify__progress-bar--info {
  background-color: #1ac1e3;
}
.Toastify__progress-bar--success {
  background-color: #3df56e;
}
.Toastify__progress-bar--error {
  background-color: #ff3737;
}
.Toastify__progress-bar--warning {
  background-color: #F04D00;
}
